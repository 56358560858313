<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative mx-2">
    <div class="position-relative">
      <div class="container-xl pt-lg-5">
        <div class="row pt-lg-5">
          <div class="col-12 col-lg-6 d-md-flex justify-content-center flex-column pt-5">
            <div class="">
              <h1 class="mt-4"><img class="img-fluid" style="border-radius:8px;" :src="`${baseUrl}/lang_images/winner_${$store.state.currentLang}.png`" alt="winner" width="320"/></h1>
              <p class="main-block__hero--p pt-3 text-primary text-center text-lg-left">{{tr('winner-ervenyes-palyazat-eseten')}}</p>
              <p class="main-block__hero--p  text-primary text-center text-lg-left" v-if="winnerType == 'main'">{{ tr('winner-hero-sub-h1-main') }}</p>            
              <p class="main-block__hero--p  text-primary text-center text-lg-left" v-if="winnerType == 'weekly'">{{ tr('winner-hero-sub-h1-weekly') }}</p>
              <p class="main-block__hero--p  text-primary text-center text-lg-left" v-if="winnerType == 'daily'">{{ tr('winner-hero-sub-h1-daily') }}</p>
              <button v-if="needBtn" class="btn btn-primary mt-4 mb-4 d-none d-lg-block" v-scroll-to="{ el: '#winnerForm', offset: -80 }">{{tr('kerem-a-nyeremenyem')}}</button>
            </div>
          </div>      
          <!-- IMG - desctop     -->
          <div class="col-12 col-lg-6 align-self-center pt-5">
            <div class="winnerType-bgr">
                <img v-if="winnerType == 'weekly'" :src="`${baseUrl}/lang_images/winnings/heti_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-azonnali')" class="img-fluid" />
                <img v-if="winnerType == 'main'" :src="`${baseUrl}/lang_images/winnings/fodij_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-heti')" class="img-fluid" />
                <!--<img v-if="winnerType == 'main'" :src="`${baseUrl}/lang_images/winnings/fodij_${$store.state.currentLang}.png`" :alt="tr('winnings-img-alt-fodij')" class="img-fluid" />-->
            </div>
          </div>
          </div>
        </div>   
    </div>
    <button v-if="needBtn" class="btn btn-primary mt-4 mb-4 d-block d-lg-none" v-scroll-to="{ el: '#winnerForm', offset: -80 }">{{tr('kerem-a-nyeremenyem')}}</button>
    <p class="real-info-txt mb-0 text-center ml-auto mr-auto text-dark">{{tr('winner-hero-info')}}</p>
  </div>
</div>
</template>

<script>
export default {
    props:['winnerType', 'needBtn']
}
</script>